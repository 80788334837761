import { getAppMetadata } from './metadata'
import {
  Configuration,
  Metadata,
  Insight,
  Audience,
  Media,
  Proximity,
  Export,
  Environment,
  Package,
  Auth,
  Poi,
  SharedPlan,
} from '@workspaces/types'

const MEDIA_TYPE_LABELS = [
  'l1panelclass',
  'l2product',
  'l2_product',
  'l3subproduct',
  'l4subproduct',
  'l5subproduct',
  'l6subproduct',
  'l7subproduct',
  'l8subproduct',
  'l9subproduct',
  'external_panel_id',
  'ad_region',
  'city',
]

export const getGenericRegionFromRegion = (regionId: number): string => {
  const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy

  let genericRegionsKey: keyof Metadata.RegionsHierarchy
  for (genericRegionsKey in genericRegions) {
    if (genericRegions[genericRegionsKey]?.id === regionId) {
      return genericRegionsKey
    }
  }

  throw Error(
    `Trying to get generic region for value ${regionId}. Check metadata file to verify the value is correct`,
  )
}

export const getRegionFromGenericRegion = (region: string): Metadata.Region => {
  const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy

  let genericRegionsKey: keyof Metadata.RegionsHierarchy
  for (genericRegionsKey in genericRegions) {
    if (genericRegionsKey.toLocaleLowerCase() === region.toLocaleLowerCase()) {
      const regionValue = genericRegions[genericRegionsKey]
      if (regionValue === undefined) {
        throw Error(
          `Trying to get region value for generic region ${region}. Check metadata file to verify the value is correct`,
        )
      }
      return regionValue
    }
  }

  throw Error(
    `Trying to get region value for generic region ${region}. Check metadata file to verify the value is correct`,
  )
}

export const getRegionsTypeId = (): string[] => {
  const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy

  let genericRegionsKey: keyof Metadata.RegionsHierarchy
  const regions: string[] = []
  for (genericRegionsKey in genericRegions) {
    const regionValue = genericRegions[genericRegionsKey]
    if (regionValue === undefined) {
      throw Error(
        `Trying to get all regions names formatted, but key ${genericRegionsKey} not found. Check metadata file to verify the value is correct`,
      )
    }
    regions.push(String(regionValue.id))
  }

  return regions
}

export const getRegionsGenericNameFromTypeId = (typeId: number): string => {
  const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy

  let genericRegionsKey: keyof Metadata.RegionsHierarchy
  for (genericRegionsKey in genericRegions) {
    const regionValue = genericRegions[genericRegionsKey]
    if (regionValue === undefined) {
      throw Error(
        `Trying to get all regions names formatted, but key ${genericRegionsKey} not found. Check metadata file to verify the value is correct`,
      )
    }
    if (regionValue.id === typeId) {
      return genericRegionsKey
    }
  }

  throw Error(
    `Not found region name formatted for type_id value ${typeId}. Check metadata file to verify the value is correct`,
  )
}

export const getRegionsTypeIdOrdered = (): number[] => {
  const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy

  let genericRegionsKey: keyof Metadata.RegionsHierarchy
  const regions: Metadata.Region[] = []
  for (genericRegionsKey in genericRegions) {
    const regionValue = genericRegions[genericRegionsKey]
    if (regionValue === undefined) {
      throw Error(
        `Trying to get all regions names formatted, but key ${genericRegionsKey} not found. Check metadata file to verify the value is correct`,
      )
    }
    regions.push(regionValue)
  }

  const regionsOrdered = regions.sort((a, b) => a.order - b.order)
  const regionsOrderedTypeId = regionsOrdered.map((region) => region.id)

  return regionsOrderedTypeId
}

export function showExcludedRangeBufferForPOIs(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_proximity.types.radius.double_buffer
}

export function getBqProject(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): string {
  const env = environment.getEnvironment()
  const bqProject = meta.bq_project
  switch (env) {
    case Environment.Environment.Development:
      return bqProject.dev
    case Environment.Environment.Staging:
      return bqProject.staging
    case Environment.Environment.Production:
      return bqProject.prod
  }
  throw new Error(
    `Could not find BQ project for environment ${env}. Check metadata file to verify values are correctly set under bq_project parameter`,
  )
}

export function getTilesetFullNameFor(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  tileset: string,
): string {
  const env = environment.getEnvironment()
  const isTesting = environment.isTesting()
  const project = getBqProject(meta, environment)
  const dataset = isTesting ? `${env}_testing` : env
  const tilesetFullName = `${project}.${dataset}.${tileset}`
  return tilesetFullName
}

export function getConnection(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): string {
  const env = environment.getEnvironment()
  return `${meta.connection}_${env}`
}

export function isDemographicsTabEnabled(meta: Metadata.AppMetadata): boolean {
  return (
    meta.features.insights.enable && meta.features.insights.tabs.demographics
  )
}

export function isImpressionsTabEnabled(meta: Metadata.AppMetadata): boolean {
  return (
    meta.features.insights.enable && meta.features.insights.tabs.impressions
  )
}

export function isOverviewTabEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.features.insights.enable && meta.features.insights.tabs.table_view
}

export function isTopIndexTabEnabled(meta: Metadata.AppMetadata): boolean {
  return (
    meta.features.insights.enable && meta.features.insights.tabs.top_indexing
  )
}

export function getMaxCustomPOIsPerFile(
  meta: Metadata.AppMetadata,
  isAdmin: boolean,
): number {
  if (meta.features.manage_pois.distinguish_between_user_and_admin) {
    return isAdmin
      ? meta.features.manage_pois.max_custom_pois_per_file_admin_user
      : meta.features.manage_pois.max_custom_pois_per_file
  }
  return meta.features.manage_pois.max_custom_pois_per_file
}

export function getMaxCustomPOIsByGeocoding(
  meta: Metadata.AppMetadata,
  isAdmin: boolean,
): number {
  if (meta.features.manage_pois.distinguish_between_user_and_admin) {
    return isAdmin
      ? meta.features.manage_pois.max_custom_pois_geocoding_admin_user
      : meta.features.manage_pois.max_custom_pois_geocoding
  }
  return meta.features.manage_pois.max_custom_pois_geocoding
}

export function getMapClusterFixedSize(meta: Metadata.AppMetadata): number {
  return meta.features.map.fixed_cluster_size
}

export function getMaxZipCodesperFile(meta: Metadata.AppMetadata): number {
  return meta.features.manage_pois.max_custom_pois_per_file
}

export function getSriteIndexForLegendIcon(index: number): number {
  const meta = getAppMetadata()
  let secureIndex = index
  if (index >= meta.features.map.legend.colors.length) {
    secureIndex = index % meta.features.map.legend.colors.length
  }

  return meta.features.map.legend.colors[secureIndex]
}

export function enableSelectingByProductInLegend(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.map.legend.enable_product_selection
}

export function defaultProductSelectionInLegend(
  meta: Metadata.AppMetadata,
): string {
  return meta.features.map.legend.default_product_selection
}

export function getIdentifiersForGenderCharts(): string[] {
  const meta = getAppMetadata()
  return meta.features.insights.features.demographics.identify_gender_charts
}

export function needToPrefilterDemographicsData(): boolean {
  const meta = getAppMetadata()
  return meta.features.insights.features.demographics
    .pre_filter_audiences_classes
}

export function needToSortDataByValuesInDemographicsData(): boolean {
  const meta = getAppMetadata()
  return meta.features.insights.features.demographics.sort_by_values
}

export function getSimpliestChartForDemographicsInsigths(): string {
  const meta = getAppMetadata()
  return meta.features.insights.features.demographics.simpliest_chart
}

export function getTemplatetForDemographicsInsigths():
  | Insight.InsightTransformation[]
  | undefined {
  const meta = getAppMetadata()
  return meta.features.insights.features.demographics.template
}

export function isAuditEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.features.audit.enable
}

export function getCartoAppConfiguration(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): {
  clientId: string
  organization?: string
} {
  const env = environment.getEnvironment()
  const cartoOrganization = meta.app_config.carto_organization

  switch (env) {
    case Environment.Environment.Development:
      return {
        clientId: meta.app_config.carto_client_id.dev,
        organization: cartoOrganization,
      }
    case Environment.Environment.Staging:
      return {
        clientId: meta.app_config.carto_client_id.staging,
        organization: cartoOrganization,
      }

    case Environment.Environment.Production:
      return {
        clientId: meta.app_config.carto_client_id.prod,
        organization: cartoOrganization,
      }

    default:
      throw Error(
        `Environment ${env} not found. Check .env file to verify VUE_APP_ENV is set.`,
      )
  }
}

export function isMultiCountry(meta: Metadata.AppMetadata): boolean {
  return meta.countries.length > 1
}

export function getCountryISO2ForNotMultiCountry(
  meta: Metadata.AppMetadata,
): string {
  return meta.countries[0].iso2
}

export function getCountriesIdsFromMetadata(
  meta: Metadata.AppMetadata,
): number[] {
  return meta.countries.map((country) => country.id)
}

export function isEnableForCurrentEnvironment(
  enableByEnvironment: Metadata.EnableByEnvironment,
  environment: Environment.EnvironmentResolver,
): boolean {
  const env = environment.getEnvironment()
  switch (env) {
    case Environment.Environment.Development:
      return enableByEnvironment.dev
    case Environment.Environment.Staging:
      return enableByEnvironment.staging
    case Environment.Environment.Production:
      return enableByEnvironment.prod
    default:
      console.error(
        `🛑 Unrecognized environment value: ${env} . Check .env file to verify VUE_APP_ENV is set.`,
      )
      return false
  }
}

export function getAudienceFeatureVersion(): Audience.AudienceFeatureVersion {
  return getAppMetadata().plan_filters.widget_audiences.version
}

export function getProximityFeatureVersion(): Proximity.ProximityFeatureVersion {
  return getAppMetadata().plan_filters.widget_proximity.version
}

export function getMediaTypeFeatureVersion(): Media.MediaTypeFeatureVersion {
  const metaData = getAppMetadata()
  const version = metaData.plan_filters.widget_media_type.version
  return version
}

export function isTotalAssetsColumnIncludedInExport(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.export.include_total_assets_column
}

export function getDefaultMethodForUploadingAssets(
  meta: Metadata.AppMetadata,
): string {
  return meta.plan_filters.widget_assets.default_option
}

export function showMethodFileForUploadingAssets(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_assets.upload_by_file
}

export function showMethodPasteForUploadingAssets(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_assets.upload_by_paste
}

export function getExportFileColumns(
  meta: Metadata.AppMetadata,
): Export.FilePOIColumns[] {
  return meta.features.export.fileColumnTitles
}

export function isExcludedFeatureForGeoboundary(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_geoboundaries.permit_exclude
}

export function getMediaTypeNameForCountry(
  meta: Metadata.AppMetadata,
  key: string,
  countryId: number,
): string | null {
  const supportedMediaTypes = [...Media.LEVELS, 'l2_product']
  if (!supportedMediaTypes.includes(key as keyof Media.MediaTypesNames)) {
    return key
  }

  const countryInfo = meta.countries.find((country) => country.id === countryId)
  if (countryInfo && countryInfo.meta_assets) {
    return (
      countryInfo.meta_assets.find(
        (t) => t.type === key || t.type.startsWith(key),
      )?.name || null
    )
  }
  return null
}

export function getMediaTypeVisibilityForCountry(
  meta: Metadata.AppMetadata,
  countryId: number,
): string[] {
  const countryInfo = meta.countries.find((country) => country.id === countryId)
  if (countryInfo && countryInfo.meta_assets) {
    return countryInfo.meta_assets.filter((t) => !t.hide).map((t) => t.type)
  }
  throw new Error(
    `No meta_assets found for country ${countryId}. Check metadata content`,
  )
}

export function getMediaTypeVisibilityForCountryAndLevel(
  meta: Metadata.AppMetadata,
  key: string,
  countryId: number,
): boolean {
  const countryInfo = meta.countries.find((country) => country.id === countryId)
  if (countryInfo && countryInfo.meta_assets) {
    const hide =
      countryInfo.meta_assets.find(
        (t) => t.type === key || t.type.startsWith(key),
      )?.hide || false
    return !hide
  }
  return true
}

export function isExcludedAssetsLegenedEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.map.legend.exclude_assets
}

export function uploadInventoryPermitExcludedAssets(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_assets.upload_inventory_permit_excluded
}

export function isSaveAsEnabled(meta: Metadata.AppMetadata): boolean {
  return (
    meta.features.save_plan.enable && meta.features.save_plan.enable_save_as
  )
}

export function isSharePlanEnabled(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): boolean {
  const setupByEnvironment = meta.features.share.enable
  const isEnabled = isEnableForCurrentEnvironment(
    setupByEnvironment,
    environment,
  )
  return isEnabled
}

export function showAssetDetailOverMap(meta: Metadata.AppMetadata): boolean {
  return meta.features.map.asset_details_over_map
}

export function getZoomLevelForDeclustering(
  meta: Metadata.AppMetadata,
): number {
  return meta.features.map.zoom_hide_cluster
}

export function getFormatFunctionForDemographicsInsights(
  meta: Metadata.AppMetadata,
  property: Insight.InsightProperties,
): Metadata.DemographicsDataFormatterFunction {
  const formatFunction = meta.features.insights.features.demographics.format
  switch (property) {
    case Insight.InsightProperties.Index:
      return formatFunction.index
    case Insight.InsightProperties.Market:
      return formatFunction.market
    case Insight.InsightProperties.Panel:
      return formatFunction.panel
    default:
      throw Error(
        `Property ${property} not found for selecting function formatter for demographics insights. Check metadata file under property:features.insights.features.demographics.format, to verify the value is correct`,
      )
  }
}

export function getAssetsBlockSize(meta: Metadata.AppMetadata): number {
  return meta.features.share.retrieve_in_blocks_of
}

export function showButtonsTooltipAsCopies(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.widgets.show_button_tooltip_as_copies
}

export function getCacheInforForModel(
  model: Metadata.DataModelBase,
): Metadata.CacheModel {
  const cache = model.cache
  if (!cache) {
    throw Error(
      `Cache information not found for model ${model.table_name}. Check metadata file to verify the value is correct`,
    )
  }
  return cache
}

export function showFacingDirectionInLegend(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.map.legend.facing_direction
}

export function showAngleMapNorthInLegend(meta: Metadata.AppMetadata): boolean {
  return meta.features.map.legend.angle_to_map_north
}

export function showBufferVisibilityControl(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.map.legend.show_buffers_visibility_control
}

export function getChunkSizeForDownloadingAssets(
  meta: Metadata.AppMetadata,
): number {
  return meta.data_model.assets.chunk_size ?? 50000
}

export function isExcludeAssetsFeatureEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.assets.exclude
}

export function switchToListViewFeatureAvailable(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_list_assets.switch_to_list_view
}

export function getCurrency(meta: Metadata.AppMetadata): string {
  return meta.units.currency
}

export function getCurrencySymbol(meta: Metadata.AppMetadata): string {
  return meta.units.currency_symbol
}

export function isPackagagesFeatureEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.packages.enable
}

export function skipPopupAndGoToSidebar(meta: Metadata.AppMetadata): boolean {
  return meta.features.assets.skip_popup_and_go_to_sidebar ?? false
}

export function showAssetsSidebarWhenDoubleClickOnAssetList(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.assets.show_sidebar_when_double_click_on_list ?? false
}

export function getColorForAvailability(
  meta: Metadata.AppMetadata,
  value: number,
): string {
  const availabilityRange = meta.features.packages.availability_range
  for (const elementInRange of availabilityRange) {
    if (elementInRange.value <= value) {
      return elementInRange.color
    }
  }
  return availabilityRange[availabilityRange.length - 1].color
}

export function getDescriptionForAvailability(
  meta: Metadata.AppMetadata,
  value: number,
): string {
  const availabilityRange = meta.features.packages.availability_range
  for (const elementInRange of availabilityRange) {
    if (elementInRange.value <= value) {
      return elementInRange.description
    }
  }
  return availabilityRange[availabilityRange.length - 1].color
}

export function getTextForAvailability(
  meta: Metadata.AppMetadata,
  value: number,
): string {
  const availabilityRange = meta.features.packages.availability_range
  for (const elementInRange of availabilityRange) {
    if (elementInRange.value <= value) {
      return elementInRange.text
    }
  }
  return availabilityRange[availabilityRange.length - 1].text
}

export function getColorForState(
  meta: Metadata.AppMetadata,
  value: Package.State,
): { background: string; foreground: string } {
  const color = meta.features.packages.states_style[value]
  if (!color) {
    throw Error(
      `Color not found for state ${value}. Check metadata file under key: states_style to verify the value is correct`,
    )
  }
  return color
}

export function getDashboardColumnsFor(
  dashboardColumns: Metadata.DashboardColumns,
  modelAccseors: boolean,
): string[] {
  if (modelAccseors) {
    return dashboardColumns.model_accessors
  }
  return dashboardColumns.locale_keys
}

export function getExportColumnsIndexToAvoidApplyingNumberFormat(
  meta: Metadata.AppMetadata,
): number[] {
  const exportColumns = meta.features.export.fields_mapping
  // get the index of the columns that has property avoidApplyingNumberFormat set to true
  const columnsToDoNotApplyNumberFormat = exportColumns
    .map((column, index) => {
      if (column.avoidApplyingNumberFormat) {
        return index
      }
      return -1
    })
    .filter((index) => index !== -1)
  return columnsToDoNotApplyNumberFormat
}

export function isManagePOIsEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.features.manage_pois.enable
}

export function isPOIsEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.plan_filters.widget_proximity.enable
}

export function getPermissionResolverVersion(
  meta: Metadata.AppMetadata,
): Auth.PermissionResolver {
  return meta.features.permission_resolver
}

export function isCustomGeoboundaryDatasetEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.custom_geoboundaries.enable
}

export function isPOIsMulticolorEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.features.proximity.multicolor
}

export function getPOIsDefaultColor(meta: Metadata.AppMetadata): string {
  return meta.features.proximity.default_color
}

export function getPOIsColors(meta: Metadata.AppMetadata): {
  ramp: string[]
  others: string
} {
  return {
    ramp: meta.features.proximity.ramp_color,
    others: meta.features.proximity.others_color,
  }
}

export function getPackageExpireConfirmationTime(
  meta: Metadata.AppMetadata,
): number {
  return meta.features.packages.expiration.booking_confirmation
}

export function getPackageExpireCancelTime(meta: Metadata.AppMetadata): number {
  return meta.features.packages.expiration.cancellation_time_limit
}

export function getISO2Flavour(meta: Metadata.AppMetadata): string {
  return meta.flavour
}

export function getBucketForAttachments(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): string {
  const env = environment.getEnvironment()
  if (!meta.app_config.buckets) {
    throw new Error(
      'Buckets setup not found in metadata file. Check app_config in metadata file',
    )
  }
  if (!meta.app_config.buckets.attachments) {
    throw new Error(
      'Attachments bucket setup not found in metadata file. Check app_config in metadata file',
    )
  }

  const bucketsByEnvironment = meta.app_config.buckets.attachments
  switch (env) {
    case 'dev':
      return bucketsByEnvironment.dev
    case 'stg':
      return bucketsByEnvironment.staging
    case 'pro':
      return bucketsByEnvironment.prod
    default:
      throw Error(
        `🛑 Getting remote function. Unrecognized environment value: ${env} . Check .env file to verify VUE_APP_ENV is set.`,
      )
  }
}

export function getCommentAttachmentsMaxSizeInMB(
  meta: Metadata.AppMetadata,
): number {
  return meta.features.comments.attachments.max_size_mb
}

export function getAreAttachmentsEnabled(meta: Metadata.AppMetadata): boolean {
  return (
    isCommentsFunctionalityEnabled(meta) &&
    meta.features.comments.attachments.enable
  )
}

export function getWelcomeMessage(
  meta: Metadata.AppMetadata,
): string | undefined {
  return meta.login_page_setup?.welcome_message
}

export function getContactMessage(
  meta: Metadata.AppMetadata,
): { message: string; email: string } | undefined {
  if (!meta.login_page_setup?.contact_message) {
    return undefined
  }
  if (
    meta.login_page_setup?.contact_message &&
    meta.login_page_setup?.contact_email
  ) {
    return {
      message: meta.login_page_setup.contact_message,
      email: meta.login_page_setup.contact_email,
    }
  }
  throw new Error('Contact message is enabled but email is not set')
}

export function getSupportedCountries(meta: Metadata.AppMetadata): string[] {
  return meta.countries.map((country) => country.iso2)
}

export function getMandatoryHeaderForCustomPOIsTemplate(
  meta: Metadata.AppMetadata,
  type: Poi.CustomPOIsImportMode,
): string[] {
  switch (type) {
    case Poi.CustomPOIsImportMode.Coordinates:
      return meta.features.manage_pois.xlsx_template_coords
        .mandatory_fields_in_template
    case Poi.CustomPOIsImportMode.Geocode:
      return meta.features.manage_pois.xlsx_template_geocode
        .mandatory_fields_in_template
  }
  throw new Error(
    `Mandatory fields not found for type ${type}. Check metadata file to verify the value is correct`,
  )
}

export function getMandatoryFiledsWithDataForCustomPOIsTemplate(
  meta: Metadata.AppMetadata,
  type: Poi.CustomPOIsImportMode,
): string[] {
  switch (type) {
    case Poi.CustomPOIsImportMode.Coordinates:
      return meta.features.manage_pois.xlsx_template_coords
        .mandatory_fields_with_data
    case Poi.CustomPOIsImportMode.Geocode:
      return meta.features.manage_pois.xlsx_template_geocode
        .mandatory_fields_with_data
  }
  throw new Error(
    `Mandatory fields not found for type ${type}. Check metadata file to verify the value is correct`,
  )
}

export function getFileNameForCustomPOIsTemplate(
  meta: Metadata.AppMetadata,
  type: Poi.CustomPOIsImportMode,
): string {
  switch (type) {
    case Poi.CustomPOIsImportMode.Coordinates:
      return meta.features.manage_pois.xlsx_template_coords.template
    case Poi.CustomPOIsImportMode.Geocode:
      return meta.features.manage_pois.xlsx_template_geocode.template
  }
  throw new Error(
    `File name not found for type ${type}. Check metadata file to verify the value is correct`,
  )
}

export function getFieldsWithAtLeastOneFullfilledForCustomPOIsTemplate(
  meta: Metadata.AppMetadata,
  type: Poi.CustomPOIsImportMode,
): string[] {
  switch (type) {
    case Poi.CustomPOIsImportMode.Coordinates:
      return []
    case Poi.CustomPOIsImportMode.Geocode: {
      const res =
        meta.features.manage_pois.xlsx_template_geocode
          .at_least_one_field_with_data_within
      if (!res) {
        throw new Error(
          `Fields not defined for type ${type}. Check metadata file property "xlsx_template_geocode.at_least_one_field_with_data_within" to verify the value is correct`,
        )
      }

      return res
    }
  }
  throw new Error(
    `Fields not found for type ${type}. Check metadata file to verify the value is correct`,
  )
}

export function getOptionalFieldsForCustomPOIsTemplate(
  meta: Metadata.AppMetadata,
  type: Poi.CustomPOIsImportMode,
): string[] {
  switch (type) {
    case Poi.CustomPOIsImportMode.Coordinates: {
      const res = meta.features.manage_pois.xlsx_template_coords.optional_fields
      if (!res) {
        throw new Error(
          `Optional fields not defined for type ${type}. Check metadata file property "xlsx_template_coords.optional_fields" to verify the value is correct`,
        )
      }

      return res
    }
    case Poi.CustomPOIsImportMode.Geocode:
      return []
  }
  throw new Error(
    `Optional fields not found for type ${type}. Check metadata file to verify the value is correct`,
  )
}

export function getFieldsForCustomPOIsDownloadTemplate(
  meta: Metadata.AppMetadata,
): string[] {
  return meta.features.manage_pois.xlsx_header_download
}

export function showPackagePriceInSharePlan(
  meta: Metadata.AppMetadata,
): boolean {
  const packageProps = meta.features.share.package
  if (packageProps === undefined) {
    return false
  }
  return packageProps.showPrice
}

export function getBasemapsConfig(
  meta: Metadata.AppMetadata,
): null | Metadata.BasemapsOptions {
  const basemapConfig = meta.app_config.basemaps
  if (!basemapConfig.enable) {
    return null
  }
  return basemapConfig
}

export function getLayersPrecedence(meta: Metadata.AppMetadata): string[] {
  return meta.features.map.layers_precedence
}

export function isAudiencesQuintileEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  if (!meta.features.audiences_quintile) {
    return false
  }
  return meta.features.audiences_quintile.enabled
}

export function isFeatureLayerHeatMapEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.map.legend.show_heatmap_layer
}

export function getCountryNameFromId(
  meta: Metadata.AppMetadata,
  countryId: number,
): string {
  const country = meta.countries.find((c) => c.id === countryId)
  if (!country) {
    throw new Error(
      `Country with id ${countryId} not found. Check metadata file to verify the value is correct`,
    )
  }
  return country.name
}

export function getCountries(
  meta: Metadata.AppMetadata,
): Configuration.Country[] {
  return meta.countries.map((country) => ({
    iso2: country.iso2,
    name: country.name,
    id: country.id,
  }))
}

export function isIntelligentMediaTypeDigitalStaticEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  const widget = meta.plan_filters.widget_media_type
  return (
    widget.enable &&
    widget.filter_digital &&
    widget.intelligent_digital_static_filter
  )
}

export function isclearActionInPanelTypeWidgetActingUponDigitalFilter(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_media_type.clear_action_acts_on_digital_filter
}

export function getMaxAssetsPerPoi(meta: Metadata.AppMetadata): number {
  const value = meta.features.proximity.max_assets_per_poi
  if (value === undefined) {
    throw new Error(
      'Max assets per POI not found. Check metadata parameter max_assets_per_poi to verify the value is set',
    )
  }

  return value
}

export function isQuickAccessToPlanVisibilityEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.plan.enable_quick_access_to_visibility
}

export function isCommentsFunctionalityEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.comments.enable
}

export function isQuickAccessCommentEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  const feature = isCommentsFunctionalityEnabled(meta)
  const featureProperty =
    meta.features.comments.show_toolbar_summary_plans_comments
  return feature && featureProperty
}

export function getRealtimeReactionForQuickAccessComments(
  meta: Metadata.AppMetadata,
): number {
  const feature = isQuickAccessCommentEnabled(meta)
  if (!feature) {
    throw new Error(
      'Trying to get realtime reaction for quick access comments, but the feature is not enabled',
    )
  }
  return meta.features.comments.realtime_reaction_in_seconds
}

export function isPlansRealtimeFeatureEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.plan.realtime.enable
}

export function getRealtimeReactionForPlans(
  meta: Metadata.AppMetadata,
): number {
  const feature = isPlansRealtimeFeatureEnabled(meta)
  if (!feature) {
    throw new Error(
      'Trying to get realtime reaction for plans, but the feature is not enabled',
    )
  }
  return meta.features.plan.realtime.reaction_in_seconds
}

export function getAudiencesQuintileFixedValuesOnSort(
  meta: Metadata.AppMetadata,
): { beginning: string[]; end: string[] } {
  if (!isAudiencesQuintileEnabled(meta)) {
    throw new Error(
      'Trying to use a function related to a disabled feature. Audiences Quintile feature is not enabled. Check metadata',
    )
  }
  const audiencesQuintile = meta.features.audiences_quintile
  if (!audiencesQuintile) {
    throw new Error('Fixed values on sort not found. Check metadata')
  }
  const beginning = audiencesQuintile.audiences.sort.beginning
  const end = audiencesQuintile.audiences.sort.end
  return { beginning, end }
}

export function getCartoConfigurationSSO(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): string | undefined {
  const cartoOrganization = meta.app_config.carto_organization

  const isTesting = environment.isTesting()
  if (isTesting) {
    const requiresSSOForTesting =
      meta.app_config.carto_organization_required_for_testing
    return requiresSSOForTesting ? cartoOrganization : undefined
  }

  return cartoOrganization
}

export function getAccessTokenForSharedPlan(
  environment: Environment.EnvironmentResolver,
): string | undefined {
  const rawData = environment.getEnvironmentVariable(
    'VUE_APP_TOKENS_SHARED_PLAN',
  )
  if (!rawData) {
    console.warn(
      'TOKENS_SHARED_PLAN environment variable is not set. Please revise configuration',
    )
    return undefined
  }

  return rawData
}

export function getDefaultBoundingBoxForStartup(
  meta: Metadata.AppMetadata,
): number[] {
  return meta.features.map.max_bbox
}

export function sharePlanIsExpiracyEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.share.expiracy.enabled
}

export function sharePlanDefaultExpiracyDate(
  meta: Metadata.AppMetadata,
): number {
  return meta.features.share.expiracy.defaultExpirationInDays
}

export function sharePlanFeedbackEmail(meta: Metadata.AppMetadata): string {
  return meta.features.share.feedback_email
}

export function sharePlanHideUnusedFilterWidgets(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.share.hide_unused_filter_widgets
}

export function sharePlanPasswordIsRequired(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.share.password_is_required
}

export function isStatsFeatureEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.features.stats.enable
}

export function isAdvanceOptionsEnabled(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): boolean {
  if (!meta.features.advance_options) {
    return false
  }
  const env = environment.getEnvironment()
  const res =
    meta.features.advance_options.enabled[
      env as keyof Metadata.EnableByEnvironmentNormalized
    ]
  if (res === undefined) {
    throw new Error(
      `Advance options are not enabled for environment ${env}. Check metadata file to verify the value is correct`,
    )
  }
  return res
}

export function isExportColumnsInAdvanceOptionsEnabled(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): boolean {
  if (!isAdvanceOptionsEnabled(meta, environment)) {
    return false
  }
  const env = environment.getEnvironment()
  const res =
    meta.features.advance_options?.export_columns.enabled[
      env as keyof Metadata.EnableByEnvironmentNormalized
    ]
  if (res === undefined) {
    throw new Error(
      `Export columns in advance options are not enabled for environment ${env}. Check metadata file to verify the value is correct`,
    )
  }
  return res
}

export function getExportFileColumnsForAssetsTab(
  meta: Metadata.AppMetadata,
): string[] {
  return meta.features.export.fields_mapping.map((field) => field.exportField)
}

export function getExportTemplateFile(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): string | null {
  const isTesting = environment.isTesting()
  const template = isTesting
    ? meta.features.export.template_file_test
    : meta.features.export.template_file
  return template && template.length > 0 ? template : null
}

export function getSharePlanAdvanceOptionsVisibility(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): SharedPlan.SharePlanAdvanceOptionsVisibility {
  const advanceOptions = meta.features.share.advance_options

  if (!advanceOptions) {
    return {
      advance_options: false,
      map: { enabled: false, map_view_only: false, layers_selection: false },
      export: false,
    }
  }

  const env = environment.getEnvironment()
  const res: SharedPlan.SharePlanAdvanceOptionsVisibility = {
    advance_options:
      advanceOptions.enabled[
        env as keyof Metadata.EnableByEnvironmentNormalized
      ] ?? false,
    map: {
      enabled:
        advanceOptions.map.enabled[
          env as keyof Metadata.EnableByEnvironmentNormalized
        ] ?? false,
      map_view_only:
        advanceOptions.map.map_view_only[
          env as keyof Metadata.EnableByEnvironmentNormalized
        ] ?? false,
      layers_selection:
        advanceOptions.map.layers_selection[
          env as keyof Metadata.EnableByEnvironmentNormalized
        ] ?? false,
    },
    export:
      advanceOptions.export[
        env as keyof Metadata.EnableByEnvironmentNormalized
      ] ?? false,
  }
  return res
}

export function getTranslationsForExportColumns(
  meta: Metadata.AppMetadata,
  countryIds: number[],
): Map<string, string> {
  if (!meta.features.export.columnsTranslation.enabled) {
    return new Map<string, string>()
  }

  let countryId = countryIds[0]
  if (countryIds.length > 1) {
    const multiCountryDefaultCountryId =
      meta.features.export.columnsTranslation.defaultCountryId
    if (!multiCountryDefaultCountryId) {
      throw new Error(
        'Default country id for translating header columns on exported files not found. Check metadata file to verify the value is set',
      )
    }
    countryId = multiCountryDefaultCountryId
  }

  const countryMetaData = meta.countries.find((c) => c.id === countryId)

  if (!countryMetaData) {
    throw new Error(
      `Country with id ${countryId} not found. Check metadata file to verify the value is correct`,
    )
  }

  let filterByLabels: string[] | null = MEDIA_TYPE_LABELS
  if (meta.features.export.columnsTranslation.enabled) {
    filterByLabels = null
  }

  const metaData = countryMetaData.meta_assets
  const translations = new Map<string, string>()
  if (metaData) {
    metaData.forEach((asset) => {
      if (filterByLabels === null || filterByLabels.includes(asset.type)) {
        translations.set(asset.type, asset.name)
      }
    })
  }

  return translations
}

export function getSharePlanPasswordValidation(
  meta: Metadata.AppMetadata,
): (pass: string) => boolean {
  return meta.features.share.passwordValidation
}

export function enableWidgetCountries(meta: Metadata.AppMetadata): boolean {
  return meta.plan_filters.widget_countries.enable
}

export function enableWidgetGeoboundaries(meta: Metadata.AppMetadata): boolean {
  return meta.plan_filters.widget_geoboundaries.enable
}

export function enableWidgetPanelTypes(meta: Metadata.AppMetadata): boolean {
  return meta.plan_filters.widget_media_type.enable
}

export function enableWidgetPanelList(meta: Metadata.AppMetadata): boolean {
  return meta.plan_filters.widget_list_assets.enable
}

export function enableWidgetPackages(meta: Metadata.AppMetadata): boolean {
  return meta.plan_filters.widget_packages.enable
}

export function enableWidgetOptimizeDistribution(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_optimize_distribution.enable
}

export function disableProductionWidgetOptimizeDistribution(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_optimize_distribution.disable_production
}

export function enableWidgetCustomPanel(meta: Metadata.AppMetadata): boolean {
  return meta.plan_filters.widget_assets.enable
}

export function enableOnlyAdminWidgetCustomPanel(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_assets.enable_only_admin
}

export function getLegal(meta: Metadata.AppMetadata): Metadata.FeatureLegal {
  return meta.features.legal
}

export function getHelp(meta: Metadata.AppMetadata): Metadata.FeatureHelp {
  return meta.features.help
}

export function isInMaintenance(meta: Metadata.AppMetadata): boolean {
  return meta.maintenance?.enabled ?? false
}

export function getDomainUnderMaintenance(
  meta: Metadata.AppMetadata,
): string[] {
  if (!isInMaintenance(meta)) {
    return []
  }
  return meta.maintenance?.domains ?? []
}

export function getMaintenanceMessageProperties(
  meta: Metadata.AppMetadata,
): string {
  if (!isInMaintenance(meta)) {
    throw new Error('Maintenance is not enabled')
  }
  const until = meta.maintenance?.until
  if (!until) {
    throw new Error('Maintenance until date is not set. This cannot happen!!!')
  }
  return until
}
